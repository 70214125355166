<template lang="pug">
.spacer(:style="{ height: `${height || 0}px` }")
</template>

<script>
import { breakpointMixin } from '@/mixins/breakpointMixin';

export default {
  name: 'Spacer',
  mixins: [breakpointMixin],
  props: {
    block: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    height() {
      if (['xs', 'sm'].includes(this.$mq)) return this.block.mobile;
      if (['md'].includes(this.$mq)) return this.block.tablet;

      return this.block.desktop;
    },
  },
};
</script>

<style lang="scss">
.spacer {
  width: 100%;
}

.wrapper:not(.pt-0) .spacer {
  margin-top: -30px;
}
</style>
